import React from 'react';
import Layout from '../../../components/layout';
import Vacancy from '../../../components/shared-components/vacancy';

const SeniorSoftwareEngineer = () => {
  return (
    <Layout>
      <Vacancy
        division="Development"
        vacancy="Senior Software Engineer"
        workType="Full time"
        // location="Yerevan, Armenia"
        intro={
          <p>
            Our technical team is looking for a Senior Software Engineer to produce and implement functional software
            solutions.
          </p>
        }
        jobResponsibilities={
          <ul>
            <li className="secondary-text">
              Improving system quality by identifying issues and common patterns, and developing standard operating
              procedures
            </li>
            <li className="secondary-text">Write well-designed, testable code</li>
            <li className="secondary-text">
              Maintaining and improving existing codebases and peer review code changes
            </li>
            <li className="secondary-text">Liaising with colleagues to implement technical designs</li>
            <li className="secondary-text">Investigating and using new technologies where relevant</li>
            <li className="secondary-text">Providing written knowledge transfer material.</li>
          </ul>
        }
        qualifications={
          <ul>
            <li className="secondary-text">Proven work experience as a Software Engineer or Software Developer</li>
            <li className="secondary-text">
              Strong programming skills in JavaScript with at least 2 years of work experience in backend development
              using Node.js
            </li>
            <li className="secondary-text">Excellent knowledge of relational databases such as PostgreSQL, MongoDB</li>
            <li className="secondary-text">
              Familiarity with the whole web stack, including protocols and web server optimization techniques
            </li>
            <li className="secondary-text">Strong analytical skills and problem-solving aptitude</li>
            <li className="secondary-text">Attention to detail.</li>
          </ul>
        }
      />
    </Layout>
  );
};

export default SeniorSoftwareEngineer;
